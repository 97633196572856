import axios, { AxiosError, AxiosResponse } from "axios";
import { INewEndpoint, INewOrganisation, INewOrganisationLogin, INewOrganisationToken, INewSchemaEndpoint, INewSchemaData, INewTemplate, INewYookrEndpoint, IOrganisation, IParsedData } from "../types/apitypes";import { IErrorResponse, Result } from "../types/errorhandlingtypes";
;

const connector_url = process.env.REACT_APP_YOOKR_URL || 'https://test.connector.yookr.org/api';

function parse_axios_response<T>(response: AxiosResponse<T>)
    : Result<T, string> 
{
    return { ok: true, value: response.data };
}

function parse_axios_error(error: AxiosError)
    : Result<never, string> 
{
    if (error.code === "ERR_NETWORK") {
        return {ok: false, error: "failed to get response from the connector - might be down"}
    }
    if (error.response && error.response.data) {
            const error_data = error.response.data as IErrorResponse;
            return {ok: false, error: error_data.error || "unexpected error" }
    }
    return {ok: false, error: "unexpected error" }
}

export function fetch_organisations()
    : Promise<Result<IOrganisation[], string>>
{
    return axios.get<IOrganisation[]>(`${connector_url}/ui`, {
        withCredentials: true
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}

/****************************************** ORGANISATION PAGE ******************************************/
/* create organisation using token */
export function post_new_organisation(new_organisation: INewOrganisation)
    :Promise<Result<any, string>>
{
    return axios.post(`${connector_url}/ui/organisation`, new_organisation, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
export function post_new_organisation_token(new_organisation: INewOrganisationToken)
    :Promise<Result<any, string>>
{
    return axios.post(`${connector_url}/ui/organisation/token`, new_organisation, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
/* create organisation using login endpoint */
export function post_new_organisation_login(new_organisation: INewOrganisationLogin)
    :Promise<Result<string[], string>>
{
    return axios.post(`${connector_url}/ui/organisation/login`, new_organisation, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
/****************************************** ORGANISATION PAGE ******************************************/

/********************************************* TOKEN PAGE **********************************************/
export function post_template(new_template: INewTemplate)
    :Promise<Result<any, string>>
{
    return axios.post(`${connector_url}/ui/template`, new_template, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
/********************************************* TOKEN PAGE **********************************************/

/********************************************* SCHEMA PAGE *********************************************/
export function post_shema_generate_endpoint_mappings(schema_generate: INewSchemaEndpoint)
    :Promise<Result<string[], string>>
{
    return axios.post(`${connector_url}/ui/schema`, schema_generate, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
export function post_shema_generate_data_mappings(schema_generate: INewSchemaData)
    :Promise<Result<string[], string>>
{
    return axios.post(`${connector_url}/ui/schema`, schema_generate, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
export function post_shema_generate_endpoint(schema_generate: INewSchemaEndpoint)
    :Promise<Result<string, string>>
{
    return axios.post(`${connector_url}/ui/schema`, schema_generate, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
export function post_shema_generate_data(schema_generate: INewSchemaData)
    :Promise<Result<string, string>>
{
    return axios.post(`${connector_url}/ui/schema`, schema_generate, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
/********************************************* SCHEMA PAGE *********************************************/

/********************************************* CONNECT PAGE *********************************************/
export function fetch_templates()
    :Promise<Result<string[], string>>
{
    return axios.get<string[]>(`${connector_url}/ui/template`, {
        withCredentials: true
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
export function post_yookr_path_schema(jsonSchema: string)
    :Promise<Result<string[], string>>
{
    return axios.post(`${connector_url}/ui/yookrpath/schema`, jsonSchema , {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
export function post_new_yookr_path(yookr_endpoint: INewYookrEndpoint)
    :Promise<Result<any, string>>
{
    return axios.post(`${connector_url}/ui/yookrpath`, yookr_endpoint, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
/********************************************* CONNECT PAGE *********************************************/

/********************************************* MIGRATE PAGE *********************************************/
export function fetch_yookr_paths(organisationID: string)
    :Promise<Result<string[], string>>
{
    return axios.get<string[]>(`${connector_url}/ui/yookrpath/${organisationID}`, 
    {
        withCredentials: true
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
export function post_organisation_path(newEndpoint: INewEndpoint)
    :Promise<Result<any, string>>
{
    return axios.post(`${connector_url}/ui/orgpath`, newEndpoint, {
        withCredentials: true,
        headers: {
        'Content-Type': 'application/json'
        }
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
/********************************************* MIGRATE PAGE *********************************************/

/********************************************* ENDPOINT PAGE *********************************************/
export function fetch_organisation_path_parsed(endpoint: String)
    :Promise<Result<IParsedData, string>>
{
    return axios.get<IParsedData>(`${connector_url}/ui/mapped/${encodeURIComponent(endpoint.toString())}`, {
        withCredentials: true
    })
    .then(parse_axios_response)
    .catch(parse_axios_error)
}
/********************************************* ENDPOINT PAGE *********************************************/