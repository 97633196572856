import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

if (process.env.REACT_APP_NAV === 'false') {
const originalFetch = window.fetch;
  window.fetch = async (...args) => {
    const requestUrl = args[0];
    if (typeof requestUrl === 'string' && requestUrl.includes('https://account.yookr.org/api/user/nav')) {
      console.log('Ignoring fetch call to:', requestUrl);
      const dummyData = {};
      const dummyResponse = new Response(JSON.stringify(dummyData), {
        status: 200,
        headers: { 'Content-Type': 'application/json' }
      });
      return Promise.resolve(dummyResponse);
    }
    return originalFetch(...args);
  };
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
