import React from 'react';
import './App.module.scss';
import Body from './components/body/Body';
import sitemap from './sitemap.json';

import { YookrLayout, YookrThemeProvider } from "yookr-common"
import { useNavigate } from 'react-router-dom';


function App() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <YookrThemeProvider>
        <YookrLayout sitemap={sitemap} currentDomain='devicemanager' routerPush={navigate}>
          <Body />
        </YookrLayout>
      </YookrThemeProvider>
    </div>
  );
}

export default App;